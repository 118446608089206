/* Built In Imports */
import NextLink from 'next/link';
import { useState } from 'react';
/* External Imports */
import { Box, Checkbox, Flex, Link } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import MidContainer from '@Layout/MidContainer';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';

/* Services */
/* Styles */

/**
 * Render Whatsapp Community Declaration Component
 * @param {Object} sectionContent - Data from api
 * @param {string} lang - Language of page
 * @param {string} region - Region of page
 * @returns {ReactElement} CommunityDeclaration component.
 */
const CommunityDeclaration = ({ sectionContent, region, lang }) => {
    const [checked, setChecked] = useState(false);
    let thankyouUrl = `/${lang}${sectionContent[3]?.linkUrl}`;
    return (
        <>
            <MidContainer p="16px">
                <Box as="h3" m="0 auto 16px auto" fontWeight={'bold'} fontSize={'22px'}>{sectionContent[0].text}</Box>
                <Flex pos="relative" justifyContent="flex-start" mt="28px">
                    <Box w="24px" mr="12px" pt="7px">
                        <Checkbox
                            pos={"relative"}
                            top="0"
                            left="0"
                            size="lg"
                            _focus={{ outline: "0" }}
                            _active={{ outline: "0" }}
                            colorScheme="orange"
                            isChecked={checked}
                            onChange={e => setChecked(!checked)}
                        />
                    </Box>
                    <Box
                        fontFamily="FedraSansStd-book"
                        fontWeight="400"
                        fontSize="18px"
                        lineHeight="30px"
                        color="#000000"
                        p={0}
                        m="0"
                    >
                        <StructuredTextParser
                            className="event-text"
                            mt="0px"
                            str={render(sectionContent[1]?.body)}
                            region={region}
                            lang={lang}
                        />
                    </Box>
                </Flex>
                <Flex px="44px" mt="40px" mb="20px" flexDir="column" alignItems="center">
                    <Box textAlign={'center'}>
                        <NextLink href={checked ? sectionContent[2]?.linkUrl : '#'}
                            passHref={checked ? true : false} legacyBehavior>
                            <Link
                                className="orange-button"
                                mb={'16px'}
                                display={'block'}
                                justifyContent="center"
                                alignItems="center"
                                fontWeight={'500'}
                                fontFamily={'FedraSansStd-medium'}
                                fontSize={'18px'}
                                bgColor={checked ? '#cf4520' : 'rgb(207 69 32 / 41%)'}
                                h={'auto'}
                                minH="50px"
                                cursor={checked ? 'pointer' : 'not-allowed'}
                                textAlign={'center'}
                                padding={'14px 29px'}
                                borderRadius="5px"
                                color="#faf7f0"
                                textDecoration="none"
                                width={'auto'}
                                target="_blank"
                                _hover={{
                                    bg: checked ? '#000054' : 'rgb(207 69 32 / 41%)',
                                }}
                            >
                                {sectionContent[2]?.buttonText}
                            </Link>
                        </NextLink>
                    </Box>
                    <Box textAlign={'center'} className='event-text' mt="20px">
                        <NextLink href={thankyouUrl} passHref legacyBehavior>
                            <Link>
                                {sectionContent[3]?.buttonText}
                            </Link>
                        </NextLink>
                    </Box>
                </Flex>
            </MidContainer>
        </>
    );
};

export default CommunityDeclaration;